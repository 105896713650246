// REPEATED WITH EVERY ROLLUPS PRODUCT

export const preparingFile = `
  **Jak poprawnie przygotować plik do druku rollup'a?**
  \n * Plik przygotowany w oparciu na konkretnym szablonie ścianki,
  \n * Plik przygotowany w palecie kolorów CMYK,
  \n * Przy formacie wektorowym wszystkie fonty zamienione na krzywe,
  \n * Przy formacie wektorowym rozdzielczość wykorzystanych bitmap (np. zdjęć) w projekcie powinny
  mieć min. 150 dpi. Przy formacie rastrowym rozdzielczość projektu powinna być nie mniejsza niż 150
  dpi. Należy pamiętać aby przy skalowaniu projektu rozdzielczość była proporcjonalnie większa np. jeśli
  przesłany projekt jest w skali 1:2, wtedy rozdzielczość powinna wynosić 300 dpi.
  \n * Plik zapisany w formacie PDF lub JPEG.
  \n Aby uzyskać głęboką czerń sugerujemy ustawienie składowych CMYK: 100-40 -40-40.
`;

export const priceListHint = 'Cena dotyczy całego zestawu w skład którego wchodzi kaseta z masztem, wydrukowaną grafiką i torbą transportową. Podane ceny są cenami netto. Cena nie zawiera kosztów wysyłki.';
export const slogan = '**<span class="center">Zamów <span class="orange">roll up tanio</span></span>**';
