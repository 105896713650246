export const pageTitle = 'Rollup reklamowy dwustronny';
export const pageDescription = 'Zapoznaj się z ofertą systemów wystawienniczych dostępną na naszej stronie internetowj. Sprawdź ekonomiczne i stabilne rollupy z wydrukiem dwustronnym.';
export const mainHeader = 'Rollup reklamowy dwustronny';
export const title = 'Rollup dwustronny';

export const rollupInfo = {
  icon: true,
  priceList: true,
  leftColumn: [
    {
      article: `**Rollup <span class="orange">Czas realizacji</span>**
      \n ok. 1-2 dni robocze`,
    },
    {
      article: `**Rollup <span class="orange">koszt wysyłki</span>**
      \n 20,00 zł/netto
      `,
    },
  ],
  rightColumn: [
    {
      article: `**Rollup <span class="orange">reklamowy</span>**
      \n Niezwykły roll-up z wydrukiem z dwóch stron. Grafiki wychodzące z jednej stabilnej kasety. Możliwość powielenia ekspozycji po obu stronach lub stworzenie dwóch różnych
      reklam. Najlepszy wybór do zaprezentowania swoich materiałów w przejściach czy korytarzach.
      \n * model dwustronny – posiada dwa wydruki
      \n * listwa typu „Express Clip“ – błyskawiczny i łatwy montaż oraz wymiana wydruku
      \n * kaseta w całości wykonana z wzmocnionego aluminium anodowanego
      \n * maszt połączony elastyczną linką zapobiegającą zgubieniu jego elementów
      `,
    },
    {
      article: `**Rollup z wydrukiem <span class="orange">specyfikacja</span>**
      \n * wysokość rozłożonego systemu: 205cm
      \n * waga systemu wraz z grafiką: ok. 6 - 7 kg (w zależności od szerokości kasety)
      \n * w zestawie: kaseta, maszt z uchwytem, listwa, wydrukowana grafika i torba transportowa
      \n Wydruk wykonany metodą mild solwent na materiale dedykowanym do systemów wystawienniczych - Blockout.
      `,
    },
    {
      article: `**Rollup <span class="orange">wymiary</span>**
      \n * ${title} 85x200cm
      \n * ${title} 100x200cm
      `,
    },
    {
      article: `**Tani rollup <span class="orange">zastosowanie</span>**
      \n * na prezentacjach,
      \n * na konferencjach,
      \n * na spotkaniach biznesowych,
      \n * w biurach,
      \n * na eventach,
      \n * w punktach sprzedaży.
      `,
    },
    {
      article: `**Tanie rollupy <span class="orange">zalety</span>**
      \n * profesjonalny i elegancki wygląd,
      \n * wygodny transport,
      \n * łatwość w rozkładaniu i składaniu,
      \n * znany i sprawdzony,
      \n * ekonomiczny.
      `,
    },
    {
      article: `**Rollup <span class="orange">projekt graficzny</span>**
      \n Jeśli potrzebują Państwo wykonać projekt graficzny zachęcamy do skorzystania z naszych usług.
      `,
    },
  ],
};
